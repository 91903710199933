<template>
	<div class="page-wrapper" :class="page.template">

		<div class="banner-wrapper" v-if="page.banner.enabled"
			:style="{
				'background-color': page.banner.color,
				'color': page.banner.textColor
			}">

			<div class="banner">
				<div>
					<h1 v-if="page.banner.position == 'left'">{{page.title[lang]}}</h1>
				</div>

				<div>
					<h1 v-if="page.banner.position == 'right'">{{page.title[lang]}}</h1>
				</div>
			</div>

		</div>

		<div class="content-wrapper">
			<div class="col-1">
				<h1 v-if="!page.banner.enabled">{{page.title[lang]}}</h1>
				<div v-html="page.body.text[lang]"></div>
			</div>

			<div class="col-2">
				<h1 v-if="!page.banner.enabled">{{page.title[lang]}}</h1>
				<div class="img-wrapper">
					<img :src="$baseUriBack + page.img">
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	props: ['page', 'lang'],

	data () {
		return {
		}
	},

	methods: {

	},

	computed: {

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.page-wrapper {
		@apply w-full;
	}

	.banner-wrapper {
		@apply w-full mb-4;
	}

	.content-wrapper {
		@apply grid grid-flow-row auto-rows-auto w-full;
	}



	.banner {
		@apply grid grid-flow-col grid-cols-2;
	}

	.banner div {
		@apply flex flex-col justify-center w-full px-4 h-24 text-left;
	}

	.banner h1 {
		@apply font-medium font-team-banner;

		font-size: 1.875rem;
	}

	.banner div:empty {
		@apply hidden;
	}

	.banner div:not(:empty) {
		@apply col-span-2;
	}

	.banner div:first-child h1 {
		@apply text-left;
	}

	.banner div:last-child h1 {
		@apply text-right;
	}



	.col-1 {
		@apply row-start-2 px-4;
	}

	.col-1 h1 {
		@apply hidden;
	}

	.col-1 >>> p {
		@apply inline-block mb-3;
		@apply text-justify font-team-body;

		font-size: 1.15rem;
	}

	.col-2 {
		@apply row-start-1 w-full mb-4 px-4;
		@apply text-left;
	}

	.col-2 .img-wrapper {
		@apply flex flex-col justify-center items-center w-full;
	}

	.col-2 img {
		@apply inline-block w-1/2 mx-auto;
	}

	.col-1 h1,
	.col-2 h1 {
		@apply mb-2 font-medium text-black;

		font-size: 2.25rem;
	}

	.col-1 h2,
	.col-2 h2 {
		@apply mt-2 mb-1 text-2xl font-normal text-black;
	}

	.col-1 h1,
	.col-1 h2 {
		@apply text-right;
	}



	@screen origame-md {
		.content-wrapper {
			@apply grid-flow-col auto-rows-auto auto-cols-auto;
		}

		.image-left-text-right .col-1 {
			@apply col-start-2;
		}

		.image-right-text-left .col-1 {
			@apply col-start-1;
		}

		.banner {
			@apply px-0;
		}

		.col-1 {
			@apply row-start-1;
		}

		.col-1 h1 {
			@apply block;
		}

		.col-2 h1 {
			@apply hidden;
		}

		.col-2 img {
			@apply w-full;
		}
	}



	@screen origame-lg {
		.banner {
			@apply w-origame-lg mx-auto;
		}

		.image-left-text-right .content-wrapper {
			@apply col-start-2;

			grid-template-columns: 5fr 8fr;
		}

		.image-right-text-left .content-wrapper {
			@apply col-start-1;

			grid-template-columns: 8fr 5fr;
		}

		.content-wrapper {
			@apply w-origame-lg mx-auto;
		}

		.col-1 {

		}

		.col-2 {

		}
	}
</style>

<template>
	<div id="app">
		<div class="content-loader-wrapper animated faster" :class="{'fadeOut delay-1s': !doingInit,
			'hidden': hide.loader}">
			<div class="content-loader">
				Origame
			</div>
		</div>

		<div v-if="!doingInit">

			<main-menu :menu="menu" :menuIdx="menuIdx" :config="config" :lang="lang"></main-menu>

			<div id="content-page-wrapper" class="content-page-wrapper">

				<div class="tile-page-wrapper">
					<isotope class="tile-page animated" :options="isotopeOptions" :list="activeTiles"
						itemSelector="isotope-item" :class="{'fadeOut hidden': route.category != 'tiles-page',
						'fadeIn': route.category == 'tiles-page'}">

							<tile v-for="tile in tiles" :id="'tile-' + tile.id" :key="tile.id" :tile="tile" :lang="lang"></tile>
					</isotope>
				</div>

				<page v-for="page in pages" :id="'page-' + page.id" :key="page.id" :lang="lang"
					:page="page" class="content-page animated" :class="{
						'fadeOut hidden': !route.content.includes(page.id),
						'fadeIn': route.content.includes(page.id)
						}"></page>

				<error-page
					v-if="activeItem.uuid == 'DEADBEEF'"
					:page="config.error['404']" :lang="lang"
					></error-page>

				<landing-page v-if="route.category == 'landing-page'" :data="landing" :config="config" 
					:carousel="carousel" :tiles="tiles" :lang="lang"
					class="animated" :class="{
						'fadeOut hidden': !route.category == 'landing-page',
						'fadeIn': route.category == 'landing-page'
						}"></landing-page>
				
				<team-page :pages="pages" :lang="lang" v-if="route.category == 'team-page'" class="animated" :class="{
						'fadeOut hidden': !route.category == 'team-page',
						'fadeIn': route.category == 'team-page'
						}"></team-page>
				
				<contact-page v-if="route.category == 'contact-page'" class="animated" :class="{
						'fadeOut hidden': !route.category == 'contact-page',
						'fadeIn': route.category == 'contact-page'
						}"></contact-page>

				<!--<isotope class="content-page" :class="route.category" :options="isotopeOptions"
					:list="contentIdx" itemSelector="isotope-item">
				</isotope>-->

			</div>

			<section-footer :config="config" :lang="lang"></section-footer>

		</div>

	</div>
</template>

<script>

export default {
	props: ['data'],

	metaInfo() {
		if(this.config.meta === undefined)
			return {};

		var meta = this.config.meta[this.lang];

		var item = this.activeItem;
		var e = {
			title: '',
			desc: ''
		};

		if(item.pageCategory == 'full-page') {
			var page = this.pages[item.pageContent[0]];

			e.title = page.meta.title[this.lang];
			e.desc  = page.meta.desc[this.lang];
			e.img   = page.meta.img;

		} else if(item.pageCategory == 'tiles-page') {
			e.title = item.title[this.lang];
			e.desc 	= item.description[this.lang];

			var found = false;
			this.route.content.forEach(function(tileIdx) {
				if(found || !tileIdx)
					return;

				var tile = this.tiles[tileIdx];
				if(tile.type == 'image-tile') {
					found = true;
					e.img = tile.image;
				}
			}.bind(this));

		} else if(item.pageCategory == 'error-page') {
			var config = this.config.error['404'];
			e.title = config[this.lang].title;
			e.img 	= config.imgSocial;

		} else if(item.pageCategory == 'team-page'){
			e.title = item.title[this.lang];
			e.desc 	= item.description[this.lang];

		} else if(item.pageCategory == 'landing-page') {
			e.title = this.landing[this.lang].text['5'];
			e.desc 	= item.description[this.lang];

		}

		// Filter title string from [x] and html
		e.title = e.title.replaceAll('[x]', ' ');
		e.title = e.title.replace(/<\/?[^>]+(>|$)/g, ' ');

		var metaArr = [
			{charset: 'utf-8'},
			{'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},
			{'http-equiv': 'Content-Type', 	content: 'text/html; charset=utf-8'},

			{name: 'viewport', 		content: 'width=device-width,initial-scale=1.0'},
			{name: 'description', 	content: e.desc},
			{name: 'keywords', 		content: meta.keywords},
			{name: 'robots', 		content: 'index, follow'},
			{name: 'language', 		content: this.lang == 'en' ? 'English' : 'Dutch'},
			{name: 'revisit-after', content: '30 days'},
			{name: 'author', 		content: meta.author},

			{property: 'og:title',		content: e.title},
			{property: 'og:description',content: e.desc},
			{property: 'og:url',		content: window.location.href},
			{property: 'og:site_name',	content: 'Origame | Folding new ideas into reality'},

			{property: 'twitter:title',		content: e.title},
			{property: 'twitter:description',content: e.desc},
			{property: 'twitter:url',		content: window.location.href}
		];

		if(e.img) {
			metaArr.push({property: 'og:image', content: e.img});
			metaArr.push({property: 'twitter:image', content: e.img});
		}

		/*
			Yet to add og:properties, like:
				og:type
					article:section
					article:published_time
					article:modified_time
				og:image
			And...
				twitter:card
				twitter:type
				twitter:image
		*/

		return {
			title: e.title,
			titleTemplate: meta.title,
			meta: metaArr
		};
	},
/*
	Frontend
	------------------------
	> PurgeCSS
	> Mobile layout
	> Menu customization (hover on menu item, coloring)
	> Big banner: change font family
	> Contact form
	> Team page

	Backend
	------------------------
	> Metadata & translation
	> Menu customization
	> Contact form API
*/

	data () {
		return {
			http: null,

			doingInit: true,
			menuDone: false,
			tilesDone: false,
			pagesDone: false,
			configDone: false,
			lang: 'nl',

			landing: {},
			carousel: [],
			tiles: {},
			pages: {},
			config: {},
			meta: {},

			hide: {
				loader: false
			},

			tilesArr: [],
			activeTiles: [],
			activeItem: null,

			pagesArr: [],

			menu: {},
			menuIdx: {uuid: {}, uri: {}, parents: [], children: {}},

			errors: [],
			route: {
				uri: '/',
				category: '',
				content: [],
				isHome: true
			},

			isotopeOptions: {
				filter: 'isActive',
				sortBy: 'getWeight',

				getFilterData:{
					isActive: (function(e) {
						if(e === undefined)
							return -1;

						return this.route.content.includes(e.id);
					}.bind(this))
				},

				getSortData: {
					getWeight: (function(e) {
						if(e === undefined)
							return -1;

						return this.route.content.indexOf(e.id);
					}.bind(this))
				}
			}
		}
	},

	mounted () {
		this.lang = window.$langCode;

		this.processMenu(window.$menus);
		this.processTiles(window.$tiles);
		this.processPages(window.$pages);
		this.processConfig(window.$config);

		this.processCarousel(window.$carousel);
		this.processLanding(window.$landingPage);

		this.loadRoute(this.$router.currentRoute.path);
		this.hideTitleScreen();

		this.doingInit = false;
	},

	watch: {
		$route(to, from) {
			// If nothing changed
			if(to.path == from.path)
				return;

			this.loadRoute(to.path);
		}
	},

	methods: {
		hideTitleScreen() {
			setTimeout(function() {
				this.hide.loader = true;
			}.bind(this), 2000);
		},

		loadRoute(uri) {
			// Determine if language needs to be changed
			if(uri.includes('/en/') || uri == '/en')
				this.lang = 'en';
			else if(uri.includes('/nl/') || uri == '/nl')
				this.lang = 'nl';
			else if(uri == '/' && this.lang == '')
				this.lang = 'nl';
			else if(uri == '/' && this.lang != '') {
				this.$router.push('/' + this.lang);
				return;
			} else
				this.lang = '';

			// @TODO Mandatory landing page visit reroute
			// if(['/', '/nl', '/en'].includes(uri) && !this.landingDone)
			// 	this.$router.push('/' + this.lang + '/landing');
			// else if(uri.includes('/landing'))
			//	this.landingDone = true;

			// Find route in menu index
			var item = this.menuIdx.uri[uri];
			if(item == undefined || this.lang == '') {

				// If you end up here, it means that either:
				//   the language could not be determined, or,
				//   the uri could not be found in menu.

				if('/nl' + uri in this.menuIdx.uri)
					this.$router.push('/nl' + uri);
				else if ('/en' + uri in this.menuIdx.uri)
					this.$router.push('/en' + uri);
				else
					this.$router.push('/nl/error-404');

				return;
			}

			// Set active menu item and update route status
			if(this.$debug)
				console.log('Loading ' + item.pageCategory + ' with content [' + item.pageContent + ']');

			this.activeItem = item;

			this.route = {
				uri 	: uri,
				category: item.pageCategory,
				content : item.pageContent,
				isHome  : uri in ['/', '/nl', '/en']
			};

			// Trigger page dimensions update
			if(this.route.category == 'full-page' && item.pageContent.length == 1)
				this.pages[item.pageContent[0]].triggers.doUpdateDimensions = true;
		},

		rawStyleToObject(raw) {
			if(typeof raw !== 'string')
				return {};

			var values = {};
			var rows = raw.split(/\r?\n/);
			
			rows.forEach(function(row) {
				row = row.replace(';', '');
				var res = row.split(':');

				if(res.length == 2)
					values[res[0]] = res[1];

			}.bind(this));
			
			return values;
		},

		mergeRawStyleWithP(styleStr, htmlStr) {
			var style = styleStr.replaceAll('\r', '');
			style = style.replaceAll('\n', ' ');
			
			return htmlStr.replaceAll('<p', '<p style="' + style + '"');
		},

		processConfig(content) {
			var d = content[0];

			this.config = {
				generic: {
					'nl': {
						lSlogan 	: d.field_l_slogan_nl,
						rSlogan 	: d.field_r_slogan_nl

					},

					'en': {
						lSlogan 	: d.field_l_slogan_en,
						rSlogan 	: d.field_r_slogan_en
					}
				},

				menu: {
					'menu-1': {
						color 		: d.field_menu2_theme_color,
						visual 		: d.field_menu_1_visual,
						logo		: d.field_menu_1_logo,
					},

					'menu-2': {
						color 		: d.field_menu2_theme_color,
						logo		: d.field_menu_2_logo,
					},

					'menu-3': {
						color 		: d.field_menu1_theme_color,
						visual 		: d.field_menu_1_visual,
						logo		: d.field_menu_1_logo,
					}
				},

				meta: {
					'nl': {
						title 		: d.field_meta_nl_title,
						keywords 	: d.field_meta_nl_keywords,
						author 		: d.field_meta_nl_author,
					},

					'en': {
						title		: d.field_meta_en_title,
						keywords	: d.field_meta_en_keywords,
						author		: d.field_meta_en_author,
					}
				},

				error: {
					'404': {
						img				: this.$baseUriBack + d.field_error_404_img,
						imgSocial : this.$baseUriBack + d.field_error_404_img_1,

						banner: {
							color		: d.field_error_404_banner_color,
							textColor: d.field_error_404_banner_t_color,
						},

						'nl': {
							title		: d.field_error_404_nl_title,
						},

						'en': {
							title 	: d.field_error_404_en_title,
						}
					}
				},

				carousel: {
					timing: d.field_carousel_timing
				},

				footer: {
					'nl': {
						html: d.field_footer_nl
					},

					'en': {
						html: d.field_footer_en
					}
				}
			};
		},

		processTiles(content) {
			var tiles = {};
			Object.values(content).forEach(function(e) {
				var tile = this.processTile(e);
				tiles[tile.id] = tile;
			}.bind(this));

			this.tiles = tiles;
			this.tilesArr = Object.values(tiles);

			this.activeTiles = this.activeTiles.concat(this.tilesArr);
			this.activeTiles.sort((a,b) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0));
		},

		processTile(e) {
			var tile = {};

			// Map API data to tile objects
			tile.id 		= e.nid;

			tile.type 		= e.type.replaceAll('_', '-');
			tile.type 		= tile.type == 'image-tile' && e.field_inverse == "True" ? 'color-tile' : tile.type;

			tile.title 		= {
				'nl': e.title.replaceAll('[x]', '<br />'),
				'en': e.field_title_en.replaceAll('[x]', '<br />')
			};

			tile.body  		= {
				'nl': e.body,
				'en': e.field_body_en
			};

			tile.image 		= this.$baseUriBack + e.field_image;
			tile.textColor 	= e.field_text_color;
			tile.color 		= e.field_color;

			switch(e.field_height) {
				case 'th-2': tile.height = 'h-tile-2'; break;
				case 'th-3': tile.height = 'h-tile-3'; break;
				case 'th-4': tile.height = 'h-tile-4'; break;
				default: tile.height = 'h-tile'; break;
			}

			tile.uri 		= {nl: '/', en: '/'}
			tile.uriType	= 'int';
			if(e.field_internal_redirect != '') {
				tile.uri = {
					'nl': '/nl' + e.link__uri,
					'en': '/en' + e.field_link_en
				}
				tile.uriType = 'int';
			} else if(e.field_external_redirect != '') {
				tile.uri = e.field_external_redirect;
				tile.uriType = 'ext';
			}

			// Additional visual status vars
			tile.hidden 	= false;

			return tile;
		},

		processPages(content) {
			var pages = {};
			Object.values(content).forEach(function(d) {

				var page = {
					id: 	d.nid,
					type: 	d.type.replaceAll('_', '-'),

					title: 	{
						'nl': d.title.replaceAll('[x]', '<br />'),
						'en': d.field_title_en.replaceAll('[x]', '<br />')
					},

					hidden: false,
					triggers: {doUpdateDimensions: false}
				};

				switch(d.type) {
					case 'two_column_page': page = this.processTeamMemberPage(d, page); break;
					case 'big_banner_page': page = this.processBigBannerPage(d, page); break;
					case 'error_page': 		page = this.processErrorPage(d, page); break;
				}
				pages[page.id] = page;

			}.bind(this));

			this.pages = pages;
			this.pagesArr = Object.values(pages);
		},

		processTeamMemberPage(d, page) {
			return {
				...page,

				template: d.field_template.replaceAll('_', '-'),
				img: d.field_image,

				uri: {
					'nl': '/nl' + d.link__uri,
					'en': '/en' + d.field_link_en
				},

				banner: {
					enabled: 	d.field_banner_title_position != 'disabled',
					position: 	d.field_banner_title_position,
					color: 		d.field_color,
					textColor: 	d.field_text_color
				},

				body: {
					text: {
						'nl': d.body,
						'en': d.field_body_en
					}
				},

				summary: {
					text: {
						'nl': d.field_summary_nl,
						'en': d.field_summary_en
					}
				},

				meta: {
					title: page.title,

					desc: {
						'nl': d.field_summary_nl_1,
						'en': d.field_summary_en_1
					},

					img: this.$baseUriBack + d.field_image_1
				}
			};
		},

		processBigBannerPage(d, page) {
			return {
				...page,

				imgColA: d.field_images_col_a.includes(', ') ? d.field_images_col_a.split(', ') : 
					d.field_images_col_a != '' ? [d.field_images_col_a] : [],
				
				imgColB: d.field_images_col_b.includes(', ') ? d.field_images_col_b.split(', ') : 
					d.field_images_col_b != '' ? [d.field_images_col_b] : [],
				
				bg: {
					position: d.field_bg_positioning,
					img: d.field_big_banner
				},

				slogan: {
					text: {
						'nl': d.field_slogan_nl,
						'en': d.field_slogan_en
					},

					align: d.field_slogan_text_align,
					spacing: d.field_slogan_line_spacing,

					anchor: 	d.field_slogan_anchor,
					textColor: 	d.field_slogan_text_color,
					bgStyle: 	d.field_slogan_bg_style,
					bgColor: 	d.field_slogan_bg_color,
					bgOpacity: 	d.field_slogan_bg_opacity,
					
					rawStyle:	this.rawStyleToObject(d.field_css_2),

					wrapper:	{
						rawStyle: this.rawStyleToObject(d.field_css_1),
					}
				},

				banner: {
					enabled: 	d.field_banner_title_position != 'disabled',
					position: 	d.field_banner_title_position,
					color: 		d.field_color,
					textColor: 	d.field_text_color
				},

				body: {
					text: {
						'nl': d.body,
						'en': d.field_body_en
					},

					topOffset: parseInt(d.field_body_top_offset) / 100,

					rawStyle: this.rawStyleToObject(d.field_css_3)
				},

				meta: {
					title: page.title,

					desc: {
						'nl': d.body_1,
						'en': d.field_body_en_1
					},

					img: this.$baseUriBack + d.field_big_banner_1
				}
			};
		},

		processCarousel(d) {
			var items = [];
			
			d.forEach(function(c) {
				var item = {
					id: c.nid,

					active: false,

					heading: {
						text: {
							nl: "",
							en: ""
						},

						style: {
							parsed: this.rawStyleToObject(c.field_css_2),
							raw: c.field_css_2
						},
						class: {},
						
						wrapper: {
							style: {
								parsed: this.rawStyleToObject(c.field_css_1),
								raw: c.field_css_1
							},
							class: {}
						}
					},

					caption: {
						text: {
							nl: "",
							en: ""
						},

						style: {
							parsed: this.rawStyleToObject(c.field_css_4),
							raw: c.field_css_4
						},
						class: {},
						
						wrapper: {
							style: {
								parsed: this.rawStyleToObject(c.field_css_5),
								raw: c.field_css_5
							},
							class: {}
						}
					},

					img: {
						url: c.field_image,

						style: {
							parsed: this.rawStyleToObject(c.field_css_7),
							raw: c.field_css_1
						},
						class: {}						
					},

					style: {
						parsed: {},
						raw: ""
					},
					class: {},

          link: c.field_link
				};

				item.heading.text.nl = c.field_heading_nl ? this.mergeRawStyleWithP(c.field_css_3, c.field_heading_nl) : '';
				item.heading.text.en = c.field_heading_en ? this.mergeRawStyleWithP(c.field_css_3, c.field_heading_en) : '';

				item.caption.text.nl = c.field_description_nl ? this.mergeRawStyleWithP(c.field_css_6, c.field_description_nl) : '';
				item.caption.text.en = c.field_description_en ? this.mergeRawStyleWithP(c.field_css_6, c.field_description_en) : '';

				items.push(item);
			}.bind(this));

			this.carousel = items;
		},

		processLanding(d) {
			var l = d[0];
			
			this.landing = {
				nl: {
					heading: {
						1: l.head_2_nl,
						2: l.head_3_nl
					},
					
					text: {
						2: l.text_2_nl,
						3: l.text_3_nl,
						4: l.text_4_nl,
						5: l.text_5_nl
					},

					html: {
						1: l.html_1_nl
					}
				},

				en: {
					heading: {
						1: l.head_2_en,
						2: l.head_3_en
					},
					
					text: {
						2: l.text_2_en,
						3: l.text_3_en,
						4: l.text_4_en,
						5: l.text_5_en
					},

					html: {
						1: l.html_1_en
					}
				},

				tiles: {
					1: l.tiles_1.includes(', ') ? l.tiles_1.split(', ') :
						l.tiles_1 != '' ? [l.tiles_1] : [],

					2: l.tiles_2.includes(', ') ? l.tiles_2.split(', ') :
						l.tiles_2 != '' ? [l.tiles_2] : [],

					3: l.tiles_3.includes(', ') ? l.tiles_3.split(', ') :
						l.tiles_3 != '' ? [l.tiles_3] : []
				},
				
				color: {
					1: l.color_1
				},

				img: {
					1: l.img_1,
					2: l.img_2,
					3: l.img_3,
					4: l.img_4
				},

				link: {
					1: l.link_1,
					2: l.link_2
				},
				
				style: {
					1: {
						parsed: this.rawStyleToObject(l.css_1),
						raw: l.css_1
					}
				}
			};
		},

		createMenuItem(uuid, parent, title, uri, desc, weight, pageCat, pageCont, hidden, menuStyle) {
			return {
				uuid 		: uuid,
				parent  	: parent,
				children 	: {},
				title 		: title,
				uri 		: uri,
				description : desc,
				weight 		: weight,
				pageCategory: pageCat,
				pageContent : pageCont,
				hidden		: hidden,
				menuStyle 	: menuStyle,
				style 		: {}
			};
		},

		processMenu(content) {
			// Check if menu has been populated
			if(!content.length)
				return [];
			
			// Sort menu so parent items end up on top
			content.sort((a,b) => (a.parent > b.parent) ? 1 : ((b.parent > a.parent) ? -1 : 0));

			var menu = {};
			var menuIdx = {uuid: {}, uri: {}, parents: [], children: {}};
			content.forEach(function(e) {
				// Catch weird exception
				if(e.parent === undefined)
					return;

				// Map API data to tile objects
				var item = this.createMenuItem(
					e.uuid,
					e.parent, {
						'nl': e.title.replaceAll('[x]', '<br />'),
						'en': e.field_menu_link_title_en.replaceAll('[x]', '<br />')
					}, {
						'nl': '/nl' + e.link__uri,
						'en': '/en' + e.field_link_en
					}, {
						'nl': e.field_meta_description,
						'en': e.field_meta_description_en
					},
					e.weight,
					e.field_page_category,
					e.field_page_content.split(', '),
					e.field_hidden == 'True' ? true : false,
					e.field_menu_style
				);

				// If parent menu item
				if(e.parent == '') {
					// Hide home menu item
					// @TODO Problematic
					if(item.title['nl'].toLowerCase() == 'home') {
						item.uuid = 'HOME';
						item.show = false;
						item.uri = {
							'nl': '/nl',
							'en': '/en'
						};
					}

					if(item.title['nl'].toLowerCase() == 'Het Team')
						item.uuid = 'TEAM';

					menu[item.uuid] = item;

					// Push parent to menu index
					menuIdx.parents.push(item);
					menuIdx.children[item.uuid] = [];
				} else {
					// If child menu item
					item.parent = e.parent.replaceAll("menu_link_content:", "");

					// If orphan child menu item
					if(item.parent === undefined)
						console.log('Missing translation for menu item "' + item.title + '"');
					else
						menu[item.parent].children[item.uuid] = item;

					// Push child item to menu index
					menuIdx.children[item.parent].push(item);
				}

				menuIdx.uuid[item.uuid] = item;

				if(item.title['nl'].toLowerCase() == 'home')
					menuIdx.uri['/'] = item;

				menuIdx.uri[item.uri.nl] = item;
				menuIdx.uri[item.uri.en] = item;
			}.bind(this));

			// Sort all parent menu items by weight
			menuIdx.parents.sort((a,b) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0));

			// Sort all submenu items by weight
			menuIdx.parents.forEach(function(e) {
				menuIdx.children[e.uuid].sort((a,b) => (a.weight > b.weight) ? -1 : ((b.weight > a.weight) ? 1 : 0));
			});


			// @TODO: determine what to do with isHome and other 'behind home vars'
			// Add additional route items
			var items = [];

			// Create error 404 page
			items.push(this.createMenuItem('DEADBEEF', '', {}, {
				'nl': '/nl/error-404',
				'en': '/en/error-404'
			}, {}, 0, 'error-page', [], 'hidden'));

			// Create landing page
			// items.push(this.createMenuItem('LANDING', '', {}, {
			//  'nl': '/nl/landing',
			//	'en': '/en/landing'
			// }, {}, 0, 'landing-page', [], 'hidden'));

			items.forEach(function(item) {
				menuIdx.uri[item.uri.nl] = item;
				menuIdx.uri[item.uri.en] = item;
			});


			this.menu = menu;
			this.menuIdx = menuIdx;
		}
	}
};
</script>

<style scoped>

.content-page-wrapper {
	/*
		@apply relative w-full flex content-center justify-center;
	*/
}

.content-loader-wrapper {
	@apply absolute top-0 left-0 w-full h-full overflow-hidden bg-white border-t-4;

	border-color: #1f9bde;
	z-index: 9000;
}

.content-loader {
	@apply flex w-full h-full items-center justify-center font-thin antialiased;

	font-size: 8rem;
	color: #1F9BDE;
}

.content-page {
	/*
		@apply absolute w-full;
	*/
}

</style>

<template>
    <div class="carousel">

        <div class="nav">
            <button class="prev-wrapper" @click="stopTimer(); prevItem()">
                <span class="prev"></span>
            </button>

            <button class="next-wrapper" @click="stopTimer(); nextItem()">
                <span class="next"></span>
            </button>
        </div>

        <div class="slide-wrapper animated" v-for="slide in slides" :key="slide.id"
                :style="slide.style.parsed" :class="{'fadeOut': !slide.active, 'fadeIn': slide.active}">
            <a class="slide" :href="slide.link ? slide.link : null" :target="slide.link ? '_blank' : null">
                
                <div class="img" :style="slide.img.style.parsed"></div>

                <div class="heading-wrapper">
                    <div class="heading-sub-wrapper" :style="slide.heading.wrapper.style.parsed" 
                        :class="slide.heading.wrapper.class">

                        <div class="heading" v-html="slide.heading.text[lang]" 
                            :style="slide.heading.style.parsed" :class="slide.heading.class">
                        </div>
                    </div>
                </div>

                <div class="caption-wrapper">
                    <div class="caption-sub-wrapper" :style="slide.caption.wrapper.style.parsed" 
                        :class="slide.caption.wrapper.class">

                        <div class="caption" v-html="slide.caption.text[lang]" 
                            :style="slide.caption.style.parsed" :class="slide.caption.class">
                            
                        </div>
                    </div>
                </div>

            </a>
        </div>
    </div>
</template>

<script>
export default {
	props: ['items', 'lang', 'config'],

	data() {
		return {
			slides: [],
            currIdx: 0,
            isTiming: true
		}
	},

	mounted() {
        this.items.forEach((item) => {
            item.img.style.parsed['background-image'] = 'url(' + this.$baseUriBack + item.img.url + ')';
            
            this.slides.push(item);
        });
        
        setInterval(function() {
            if(this.isTiming)
                this.nextItem();
        }.bind(this), this.config.carousel.timing);

        this.showItem(this.currIdx);
	},

	methods: {
		prevItem() {
            this.hideItem(this.currIdx);

            if(this.currIdx == 0)
                this.currIdx = this.slides.length - 1;
            else
                this.currIdx -= 1;
            
            this.showItem(this.currIdx);
        },

        nextItem() {
            this.hideItem(this.currIdx);

            if(this.currIdx == this.slides.length - 1)
                this.currIdx = 0;
            else
                this.currIdx += 1;
            
            this.showItem(this.currIdx);
        },

        hideItem(idx) {
            this.slides[idx].active = false;
        },

        showItem(idx) {
            this.slides[idx].active = true;
        },

        stopTimer() {
            this.isTiming = false;
        }
    }
}
</script>

<style scoped>

.carousel {
    @apply relative w-full h-full;
}

.nav .prev-wrapper {
    @apply absolute flex flex-col items-center justify-center w-12 h-12;

    left: 1rem;
    top: calc(50% - 1.5rem);
    z-index: 9999;
}

.nav .prev {
    @apply w-0 h-0;
    
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-right: 0.75rem solid #ffffff;
    margin-left: -.4rem;
}

.nav .next-wrapper {
    @apply absolute flex flex-col items-center justify-center w-12 h-12;

    right: 1rem;
    top: calc(50% - 1.5rem);
    z-index: 9999;
}

.nav .next {
    @apply w-0 h-0;
    
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-left: 0.75rem solid #ffffff;
    margin-right: -.4rem;
}

.slide-wrapper {
    @apply absolute top-0 left-0 w-full h-full;
}

.slide-wrapper.fadeIn {
  @apply z-50;
}

.slide {
    @apply block relative w-full h-full;
}

.slide .img {
    @apply block w-full h-full bg-cover bg-no-repeat;
}

.slide .heading-wrapper {
    @apply absolute top-0 w-full;
}

.slide .heading-sub-wrapper {
    @apply w-full;
}

.slide .heading {

}

.slide .caption-wrapper {
    @apply absolute bottom-0 w-full;
}

.slide .caption-sub-wrapper {
    @apply w-full;
}

@screen origame-0-md {
    .slide .heading {
        @apply m-0 p-0 !important;
    }

    .slide .heading >>> p {
        @apply inline-block px-8 py-4 mx-auto my-4 !important;
    }

    .slide .heading,
    .slide .heading >>> p {
        @apply text-carousel-heading-sm !important;
    }

    .slide .caption {
        @apply m-0 p-0 !important;
    }

    .slide .caption >>> p {
        @apply inline-block px-8 py-4 mx-auto my-4 !important;
    }
    .slide .caption,
    .slide .caption >>> p {
        @apply text-carousel-caption-sm !important;
    }
}

@screen origame-md {
    
    .slide .img {
        @apply absolute top-0 left-0 h-full;
    }

    .slide .heading-wrapper {
        @apply absolute top-0 left-0 h-full;
    }

    .slide .heading-sub-wrapper {
        @apply relative flex flex-col h-full;
    }

    .slide .heading {
        @apply block;
    }

    .slide .caption-wrapper {
        @apply absolute top-0 left-0 h-full;
    }

    .slide .caption-sub-wrapper {
        @apply relative flex flex-col h-full;
    }

    .slide .caption {
        @apply block;
    }
}



</style>
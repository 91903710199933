<template>
    <div class="footer" v-html="config.footer[lang].html"></div>
</template>

<script>
export default {
	props: ['config', 'lang'],
}
</script>

<style scoped>
    .footer {
        @apply grid grid-flow-row w-full pt-4;
        
        background-color: #232324;
    }

    .footer >>> * {
        @apply font-footer text-center text-white;

        font-size: 1.15rem;
    }

    .footer >>> div {
        @apply text-center mb-4;
    }

    @screen origame-sm {
        .footer {
            @apply grid-flow-col py-12;
        }

        .footer >>> div {
            @apply m-0;
        }
    }
</style>
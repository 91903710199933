<template>
<div>
	<div id="menu" class="menu-wrapper" :class="activeItem.menuStyle" :style="el['menu'].style">

		<div class="menu">
			<div class="menu-toggle" v-on:click="isMenuOpen = !isMenuOpen">
				<div class="icon">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="company-name">
				<router-link :to="'/'">
					<img :src="el['menu'].logo">
				</router-link>
			</div>

			<div class="company-logo">
				<router-link :to="'/'" class="origame-fade" :class="el['company-logo'].class">
					<img :src="el['menu'].visual">
				</router-link>
			</div>

			<div class="menu-items accordion" :class="{'open': isMenuOpen == true}">
				<div v-for="item in menuIdx.parents" :key="item.uuid">
					<router-link class="menu-item origame-fade" v-if="!item.hidden" :to="item.uri[lang]"
						:class="{'router-link-active': item.uuid == activeParent, ...el['menu-item'].class}"
						v-html="item.title[lang]">
					</router-link>

					<div class="submenu-items accordion"
						:class="{'open': activeItem.uuid == item.uuid || activeItem.parent == item.uuid}">

						<router-link class="submenu-item origame-fade origin-top" 
							:class="el['submenu-item'].class" v-for="sub in item.children" 
							:key="sub.uuid" :to="sub.uri[lang]" v-html="sub.title[lang]">
						</router-link>
					</div>

				</div>

				<div class="flag-items">
					<router-link class="flag-item dutch origame-fade" :to="activeItem.uri['nl']"></router-link>
          <router-link class="flag-item english origame-fade" :to="activeItem.uri['en']"></router-link>
        </div>
			</div>
		</div>

		<div class="submenu origame-fade">
			<div class="slogan-left origame-fade" :class="el['slogan-left'].class">{{config.generic[lang].lSlogan}}</div>

			<div class="submenu-items" :class="el['submenu-items'].class">
				<router-link class="submenu-item origame-fade animated fadeIn faster" :class="el['submenu-item'].class"
					v-for="item in menuIdx.children[activeParent]"
					:key="item.uuid" :to="item.uri[lang]" v-html="item.title[lang]">

				</router-link>
			</div>

			<div class="slogan-right origame-fade" :class="el['slogan-right'].class">{{config.generic[lang].rSlogan}}</div>
		</div>

	</div>
</div>
</template>

<script>
export default {
	props: ['menu', 'menuIdx', 'config', 'lang'],

	data () {
		return {
			isMenuOpen: true,

			activeParent: '',
			activeItem: {uri: {nl: '/', en: '/'}},
			submenu: [],

			el: {
				'menu': {
					style: {},
					class: {},
					logo: '',
					visual: '',
					color: 'rgb(255, 255, 255)'
				},

				'company-logo': {
					style: {},
					class: 'opacity-100'
				},

				'menu-item': {
					style: {},
					class: {}
				},
				
				'submenu-items': {
					class: {}
				},

				'submenu-item': {
					style: {},
					class: {}
				},

				'slogan-left': {
					style: {},
					class: 'opacity-100'
				},

				'slogan-right': {
					style: {},
					class: 'opacity-100'
				}

			}
		}
	},

	mounted() {
		this.waitForInit();
	},

	methods: {
		waitForInit() {
			setTimeout(function() {
				if(this.menu && this.config)
					this.findAndSetActiveItem(this.$router.currentRoute.path);
				else
					this.waitForInit();
			}.bind(this), 250);
		},

		findAndSetActiveItem(path) {
			var item = this.findItem(path);
			if(item === false)
				return false

			this.activeItem = item;
			this.fillSubmenu(item);
			this.setMenuStyle(item.menuStyle);

			return true;
		},

		findItem(path) {
			var item = this.menuIdx.uri[path];
			if(item == undefined)
				return false;
			return item;
		},

		fillSubmenu(item) {
			var submenu = [];
			if(item.parent == '') {
				this.activeParent = item.uuid;
				submenu = item.children;
			} else {
				var parent = this.menuIdx.uuid[item.parent];
				if(parent === undefined)
					return;

				this.activeParent = parent.uuid;
				submenu = parent.children;
			}

			if(this.submenu != submenu)
				this.submenu = submenu;
		},

		setMenuStyle(type) {
			/* Because of the way the visual is set up, you can't __just__ make it disappear
				when switching menu styles. Otherwise the fade animation will stop working.
				Always make sure to have some kind of visual from the past to keep the fill */

			var backUri = this.$baseUriBack;
			var menuConfig = this.config.menu[type];

			if(type == 'menu-1') {

				this.el['menu'].style = {'border-color': menuConfig.color};
				this.el['menu'].logo = backUri + menuConfig.logo;
				this.el['menu'].visual = backUri + menuConfig.visual;
				this.el['menu'].color = menuConfig.color;

				this.el['submenu-items'].class = this.processTheme(menuConfig.color);
				this.el['slogan-left'].class = 'hidden';
				this.el['slogan-right'].class = 'hidden';
				this.el['company-logo'].class = 'opacity-100';

			} else if(type == 'menu-2') {

				this.el['menu'].style = {'border-color': menuConfig.color};
				this.el['menu'].logo = backUri + menuConfig.logo;
				this.el['menu'].color = menuConfig.color

				this.el['submenu-items'].class = this.processTheme(menuConfig.color);
				this.el['slogan-left'].class = 'hidden';
				this.el['slogan-right'].class = 'hidden';
				this.el['company-logo'].class = 'opacity-0';

			} else if(type == 'menu-3') {

				this.el['menu'].style = {'border-color': menuConfig.color};
				this.el['menu'].logo = backUri + menuConfig.logo;
				this.el['menu'].visual = backUri + menuConfig.visual;
				this.el['menu'].color = menuConfig.color;

				this.el['submenu-items'].class = this.processTheme(menuConfig.color);
				this.el['slogan-left'].class = 'hidden';
				this.el['slogan-right'].class = 'hidden';
				this.el['company-logo'].class = 'opacity-100';

			}
		},

		processTheme(color) {
			var theme = 'blue';
			switch(color) {
				case '#fdd739': theme = 'yellow'; break;
				case '#9ec84c': theme = 'green'; break;
				case '#ff6600': theme = 'orange'; break;
				case '#1f9bde': theme = 'blue'; break;
				case '#d1c4b4': theme = 'beige'; break;
				case '#3d3930': theme = 'gray'; break;
				case '#da43a3': theme = 'pink'; break;
				case '#000000': theme = 'black'; break;
			}
			return theme;
		}
	},

	watch: {
		$route(to, from) {
			// If nothing changed
			if(to.path == from.path)
				return;

			this.findAndSetActiveItem(to.path);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bg-menu {
	background-color: #1F9BDE;
}

.a-menu {
	color: rgb(150, 150, 150);
}

.border-menu {
	border-color: #1F9BDE;
}



.menu-wrapper {
	@apply relative w-full max-w-full pt-4 mb-2 border-t-4;
}

/*
.menu-wrapper.w-full {
	@apply w-full;
}

.menu-wrapper.w-fixed {
	width: 1170px;
}*/

.menu {
	@apply grid grid-flow-row border-b-2;

	grid-template-columns: auto 1fr auto;
	
	border-color: #696969;
}

.company-name {
	@apply flex items-center justify-center row-start-1 col-start-2 text-center;
}

.company-name img {
	@apply inline-block;
}

.company-logo {
	@apply flex items-center justify-end;
	@apply row-start-1 col-start-1;
}

.company-logo img {
	@apply inline-block w-menu-logo;
  margin-top: 10px;
}

.menu-toggle {
	@apply flex self-center justify-self-start;
	@apply row-start-1 col-start-3;
	@apply w-12 h-12;
	@apply border-2 border-origame-prim rounded-md;
}

.menu-toggle .icon {
	@apply flex flex-col justify-center w-full;
}

.menu-toggle .icon > div {
	@apply block;

	height: 3px;
	width: 65%;
	margin: 2.5px auto;
	background: #969696;
	border-radius: 1.5px;
}

.menu-items {
	@apply col-span-3 mt-6;
}



.menu-item {
	@apply flex flex-row items-center justify-center w-full h-16;
	@apply text-center font-bold bg-white;

	color: rgb(150, 150, 150);
}

.flag-items {
  margin-bottom: 20px;
  text-align: center;
}

.flag-item {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-top: 15px;
  background-size: 100% 100%;
  opacity: 0.5;
}
.flag-item.router-link-active {
  opacity: 1;
}

.flag-item.dutch {
  margin-right: 10px;
  background-image: url('~@/assets/img/nl.png');
}
.flag-item.english {
  background-image: url('~@/assets/img/en.png');
}

.menu-item:hover,
.menu-item.router-link-active {
	@apply text-white;

	background-color: #1F9BDE;
}

.menu-items .submenu-item {
	@apply flex flex-row items-center justify-center w-full h-16;
	@apply text-center;

	background: rgb(240, 240, 240);
}

.menu-items .submenu-item:hover,
.menu-items .submenu-item.router-link-active {
	@apply bg-gray-200;
}

.submenu {
	@apply hidden;
}


@screen origame-md {
	.menu-toggle {
		@apply hidden;
	}
	
	.menu-items.accordion {
		@apply overflow-visible max-h-full;
	}
	
	.menu {
		@apply grid-flow-col;
		@apply flex-row items-end w-origame-md pb-4 ml-auto mr-auto;
		@apply border-b-0;

		grid-template-rows: 1fr;
		grid-template-columns: auto auto 1fr;
	}

	.company-name {
		@apply flex-none w-menu-name;
		@apply col-start-1;
	}

	.company-logo {
		@apply relative flex-none inline-block bg-white;
		@apply col-start-2;

		top: 18px;
		width: 154px;
		max-width: 154px;
		height: 2px;
	}

	.company-logo a {
		@apply absolute w-full text-center;

		top: -95px;
	}



	.menu-items {
		@apply flex flex-1 flex-wrap justify-around;
		@apply col-start-3;
	}

	.menu-items .submenu-items {
		@apply hidden;
	}

	.menu-item {
		@apply flex flex-col justify-center h-full px-4 py-1;
		@apply rounded-md outline-none;

		font-size: .95rem;

		/* max-width: 140px; */
	}

  .flag-items {
    margin-bottom: 0;
  }



	.submenu {
		@apply grid w-origame-md mx-auto;
		@apply border-t-2;

		grid-template-columns: auto 1fr auto;
		border-color: #e3e3e3;
	}

	.slogan-left,
	.slogan-right {
		@apply italic text-sm;

		padding-top: .75rem;
		font-size: .9rem;
	}

	.slogan-right {
		@apply text-right;
	}

	.submenu .submenu-items {
		@apply flex justify-end flex-nowrap;
		@apply row-start-2 col-span-3 mt-2;

		font-size: .9rem;
		color: #ff6600;
	}

	.submenu .submenu-item {
		@apply flex-none text-center border border-transparent rounded py-1 px-3 ml-2;

		height: max-content;
	}

	.submenu .submenu-item:first-child {
		@apply ml-0;
	}

	.submenu-items.yellow .submenu-item:hover,
	.submenu-items.yellow .submenu-item.router-link-active {
		@apply text-white;
		background-color: #fdd739;
	}

	.submenu-items.green .submenu-item:hover,
	.submenu-items.green .submenu-item.router-link-active {
		@apply text-white;
		background-color: #9ec84c;
	}

	.submenu-items.orange .submenu-item:hover,
	.submenu-items.orange .submenu-item.router-link-active {
		@apply text-white;
		background-color: #ff6600;
	}

	.submenu-items.blue .submenu-item:hover,
	.submenu-items.blue .submenu-item.router-link-active {
		@apply text-white;
		background-color: #1f9bde;
	}

	.submenu-items.beige .submenu-item:hover,
	.submenu-items.beige .submenu-item.router-link-active {
		@apply text-white;
		background-color: #d1c4b4;
	}

	.submenu-items.gray .submenu-item:hover,
	.submenu-items.gray .submenu-item.router-link-active {
		@apply text-white;
		background-color: #3d3930;
	}

	.submenu-items.pink .submenu-item:hover,
	.submenu-items.pink .submenu-item.router-link-active {
		@apply text-white;
		background-color: #da43a3;
	}

	.submenu-items.black .submenu-item:hover,
	.submenu-items.black .submenu-item.router-link-active {
		@apply text-white;
		background-color: #000000;
	}
}

@screen origame-lg {
	.menu {
		@apply w-origame-lg;
	}

	.submenu {
		@apply w-origame-lg;
	}

	.submenu .submenu-items {
		@apply justify-end flex-nowrap;
		@apply row-start-1 col-start-2 col-span-1;
		@apply pr-4 ml-2;
	}
}















.menu-2 .menu-item:hover,
.menu-2 .menu-item.router-link-active {
	/*@apply bg-transparent underline;*/

	/*color: rgb(150, 150, 150);*/
}

.menu-1 .submenu, .menu-2 .submenu {
	@apply border-transparent;
}

</style>

<template>
	<div class="page-wrapper">

		<div class="banner-wrapper" v-if="page.banner.enabled" :style="{'background-color': page.banner.color, 'color': page.banner.textColor}">
			<div class="banner">
				<div><h1 v-if="page.banner.position == 'left'" v-html="page.title[lang]"></h1></div>
				<div><h1 v-if="page.banner.position == 'right'" v-html="page.title[lang]"></h1></div>
			</div>
		</div>

		<div class="bg-wrapper" :class="page.bg.position" ref="bgWrapper">
			<div class="bg-img-wrapper">
				<img :src="$baseUriBack + page.bg.img" />

				<div class="slogan-wrapper" :style="wrapper.style" :class="wrapper.class">
					<div class="slogan-sub-wrapper" :style="subWrapper.style" :class="subWrapper.class">
						<div class="slogan" v-html="page.slogan.text[lang]" :style="slogan.style" :class="slogan.class"></div>
					</div>
				</div>
	
			</div>
		</div>

		<div class="col-wrapper">

			<div class="col-1">
				<img :src="$baseUriBack + imgSrc" v-for="(imgSrc, index) in page.imgColA" :key="index" />
			</div>

			<div class="col-2">
				<div class="body-wrapper" :style="body.style">
					<div class="text" v-html="page.body.text[lang]"></div>
				</div>
			</div>

			<div class="col-3">
				<img :src="$baseUriBack + imgSrc" v-for="(imgSrc, index) in page.imgColB" :key="index" />
			</div>

		</div>

	</div>
</template>

<script>

export default {
	props: ['page', 'lang'],

	data () {
		return {
			wrapper: {},
			subWrapper: {},
			slogan: {},
			body: {},
			initTrigger: false
		}
	},

	mounted() {
		var slogan = this.page.slogan;
		slogan.style = this.page.slogan.rawStyle;
		slogan.class = [];

		var wrapper 	= {style: {}, class: []};
		var subWrapper 	= {style: this.page.slogan.wrapper.rawStyle, class: []};

		/* Determine slogan wrapper anchor point */
		var verPos = ''; var horPos = '';
		switch(slogan.anchor) {
			case 'top_left'		: verPos = 'flex-start'; horPos = 'flex-start'; break;
			case 'top_center'	: verPos = 'flex-start'; horPos = 'center'; break;
			case 'top_right'	: verPos = 'flex-start'; horPos = 'flex-end'; break;
			case 'center_left'	: verPos = 'center'; horPos = 'flex-start'; break;
			case 'center_center': verPos = 'center'; horPos = 'center'; break;
			case 'center_right'	: verPos = 'center'; horPos = 'flex-end'; break;
			case 'bottom_left'	: verPos = 'flex-end'; horPos = 'flex-start'; break;
			case 'bottom_center': verPos = 'flex-end'; horPos = 'center'; break;
			case 'bottom_right'	: verPos = 'flex-end'; horPos = 'flex-end'; break;
		}
		wrapper.style['align-items'] 	= horPos;
		wrapper.style['justify-content'] = verPos;
		

		/* Determine slogan's styling */
		slogan.style['color']		= slogan.textColor;

		if(slogan.bgStyle != 'no_bg') {
			slogan.class.push(slogan.bgStyle);
			slogan.class.push(slogan.align);

			slogan.style['background-color'] = slogan.bgColor;

			if(slogan.bgStyle == 'mark-block')
				slogan.style['line-height'] = slogan.spacing;
			else
				slogan.style['margin-bottom'] = slogan.spacing;
		}
		
		this.slogan = slogan;
		this.wrapper = wrapper;
		this.subWrapper = subWrapper;


		/* Determine body styling */
		var body = {style: this.page.body.rawStyle, class: []};

		var height = this.$refs.bgWrapper.clientHeight;
		height = this.page.body.topOffset * height;

		body.style['margin-top'] = height + 'px';

		this.body = body;


		/* Init observer to listen to dimension updates */
		var targetNode = document.getElementById('page-' + this.page.id);

		var observer = new MutationObserver(function() {
			if(targetNode.style.display != 'none' && this.page.triggers.doUpdateDimensions) {
				var height = this.$refs.bgWrapper.clientHeight;
				height = this.page.body.topOffset * height;

				var style = {};
				style = {...style, ...this.body.style};
				style['margin-top'] = height + 'px';
				this.body.style = style;
			}
		}.bind(this));

		observer.observe(targetNode, { attributes: true, childList: true });
	},

	methods: {

	},

	computed: {

	}

	}
</script>

<style scoped>
	.page-wrapper,
	.banner-wrapper,
	.bg-wrapper,
	.col-wrapper {
		@apply w-full max-w-full;
	}

	.content-wrapper {
		@apply grid grid-flow-row auto-rows-auto w-full;
	}



	.banner {
		@apply grid grid-flow-col grid-cols-2;
	}

	.banner div {
		@apply flex flex-col justify-center w-full px-4 h-24 text-left;
	}

	.banner h1 {
		@apply text-3xl font-big-banner-banner;
	}

	.banner div:empty {
		@apply hidden;
	}

	.banner div:not(:empty) {
		@apply col-span-2;
	}

	.banner div:first-child h1 {
		@apply text-left;
	}

	.banner div:last-child h1 {
		@apply text-right;
	}



	.bg-wrapper img {
		@apply block w-full;
	}

	.slogan-sub-wrapper {
		@apply flex flex-col w-max;
	}


	

	.slogan >>> * {
		@apply font-big-banner-slogan;
	}



	.slogan.mark-block.left {
		@apply text-left;
	}

	.slogan.mark-block.center {
		@apply text-center;
	}

	.slogan.mark-block.right {
		@apply text-right;
	}

	.slogan.mark-block >>> p {
		line-height: inherit;
	}



	.slogan.mark-lines {
		@apply contents;

		background-color: none;
		padding: none;
	}

	.slogan.mark-lines >>> p {
		@apply inline-block my-0 mx-auto;

		background-color: inherit;
		padding: inherit;
		margin-bottom: inherit;
	}

	.slogan >>> p:last-child {
		margin-bottom: 0;
	}

	.slogan.mark-lines.left >>> p {
		@apply ml-0 mr-auto text-left;
	}

	.slogan.mark-lines.center >>> p {
		@apply mx-auto text-center;
	}

	.slogan.mark-lines.right >>> p {
		@apply ml-auto mr-0 text-right;
	}


	
	.col-2 .text >>> p,
	.col-2 .text >>> ul,
	.col-2 .text >>> li {
		@apply font-big-banner-body;

		font-size: 1.15rem;
	}

	.col-2 .text >>> strong,
	.col-2 .text >>> b {
		@apply font-big-banner-body-highlights font-semibold;

		font-size: 1.25rem;
	}
	
	.col-2 .text >>> ul {
		@apply list-disc pl-4 mb-3;
	}

	.col-2 .text >>> li {
		@apply mb-1;
	}



	@screen origame-0-md {
		.slogan-wrapper {
			@apply px-4 py-8 !important;
		}

		.slogan-sub-wrapper {
			@apply w-full m-0 p-0 !important;
		}


		.col-2 .body-wrapper {
			@apply m-0 p-0 !important;
		}
	}



	.col-wrapper {
		@apply grid grid-flow-row auto-rows-auto px-3;
	}

	.col-1 img,
	.col-3 img {
		@apply block w-full mb-4;
	}

	.col-2 {
		@apply relative row-start-2;
	}

	.col-3 {
		@apply row-start-1;
	}

	
	@screen origame-0-xxs {
		.slogan >>> * {
			@apply text-big-banner-slogan-base !important;
		}
	}
	
	@screen origame-xxs-xs {
		.slogan >>> * {
			@apply text-big-banner-slogan-xxs !important;
		}
	}

	@screen origame-xs-sm {
		.slogan >>> * {
			@apply text-big-banner-slogan-xs !important;
		}
	}

	@screen origame-sm-md {
		.slogan >>> * {
			@apply text-big-banner-slogan-sm !important;
		}
	}


	@screen origame-md {
		.banner {
			@apply px-0;
		}

		.banner h1 {
			font-size: 1.875rem;
		}

		.banner h1:first-child {
			@apply pr-3 text-right;
		}

		.banner h1:last-child {
			@apply pl-3 text-left;
		}



		.bg-wrapper {
			@apply w-full;
		}

		.bg-img-wrapper {
			@apply relative;
		}

		.bg-wrapper.full-cover {
			@apply block w-full h-auto;
		}

		.bg-wrapper.full-cover img {
			@apply w-full object-cover object-top;

			max-height: 60vh;
		}

		.bg-wrapper.partial-cover .bg-img-wrapper {
			@apply w-max mx-auto;
		}

		.bg-wrapper.partial-cover img {
			@apply block w-full mx-auto;
		}

		.bg-sub-wrapper {
			@apply relative;
		}

		.bg-sub-wrapper img {
			@apply block w-full h-auto mx-auto;
		}



		.slogan-wrapper {
			@apply absolute top-0 left-0 flex flex-col w-full h-full;
		}

		.body-wrapper {
			background: rgba(255, 255, 255, 0.9);
		}



		.col-wrapper {
			@apply grid-flow-col auto-rows-auto grid-cols-2 auto-cols-auto gap-x-4 mt-4;

			grid-template-columns: 4fr 6fr;
		}

		.col-1 {
			@apply row-span-2;
		}

		.col-2 {
			@apply row-start-1 col-start-2;
		}

		.col-3 {
			@apply row-start-2 col-start-2;
		}
	}



	@screen origame-lg {
		.banner,
		.col-wrapper {
			@apply mx-auto w-origame-lg;
		}

		.banner,
		.banner h1 {
			@apply p-0;
		}

		.bg-wrapper.partial-cover img {
			@apply w-origame-lg;
		}

		.col-wrapper {
			@apply gap-x-8 pl-0 pr-8;

			grid-template-columns: 4fr 6fr;
		}

		.col-3 img {
			@apply mx-auto;
      width: 91%;
		}
	}



</style>

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import axios from 'axios'
import isotope from 'vueisotope'

import '@/assets/css/style.css'

Vue.use(VueRouter)
Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.prototype.$appName = 'Origame'
Vue.prototype.$baseUri = window.$baseUri
Vue.prototype.$baseUriBack = window.$baseUriBack
Vue.prototype.$http = axios.create({baseURL: Vue.prototype.$baseUriBack})
Vue.prototype.$debug = true

import Menu from './components/Menu.vue'
import Tile from './components/Tile.vue'
import Page from './components/Page.vue'
import TeamMemberPage from './components/TeamMemberPage.vue'
import BigBannerPage from './components/BigBannerPage.vue'
import ErrorPage from './components/ErrorPage.vue'
import LandingPage from './components/LandingPage.vue'
import TeamPage from './components/TeamPage.vue'
import ContactPage from './components/ContactPage.vue'
import Footer from './components/Footer.vue'
import Carousel from './components/Carousel.vue'

Vue.component('main-menu', Menu)
Vue.component('tile', Tile)
Vue.component('page', Page)
Vue.component('team-member-page', TeamMemberPage)
Vue.component('big-banner-page', BigBannerPage)
Vue.component('error-page', ErrorPage)
Vue.component('landing-page', LandingPage)
Vue.component('team-page', TeamPage)
Vue.component('contact-page', ContactPage)
Vue.component('section-footer', Footer)
Vue.component('isotope', isotope)
Vue.component('carousel', Carousel)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [],

	scrollBehavior() {
		window.scrollTo(0,0);
		/*if(document.getElementById('menu') && screen.width <= 830) {
			setTimeout(function(){
				var menu = document.getElementById('menu').getBoundingClientRect();
				var page = document.getElementById('content-page-wrapper').getBoundingClientRect();
				
				window.scroll({
					top: Math.abs(menu.top) + Math.abs(page.top), 
					left: 0, 
					behavior: 'smooth'
				});
			}, 500);
		}*/
	}
});

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"content-loader-wrapper animated faster",class:{'fadeOut delay-1s': !_vm.doingInit,
		'hidden': _vm.hide.loader}},[_c('div',{staticClass:"content-loader"},[_vm._v(" Origame ")])]),(!_vm.doingInit)?_c('div',[_c('main-menu',{attrs:{"menu":_vm.menu,"menuIdx":_vm.menuIdx,"config":_vm.config,"lang":_vm.lang}}),_c('div',{staticClass:"content-page-wrapper",attrs:{"id":"content-page-wrapper"}},[_c('div',{staticClass:"tile-page-wrapper"},[_c('isotope',{staticClass:"tile-page animated",class:{'fadeOut hidden': _vm.route.category != 'tiles-page',
					'fadeIn': _vm.route.category == 'tiles-page'},attrs:{"options":_vm.isotopeOptions,"list":_vm.activeTiles,"itemSelector":"isotope-item"}},_vm._l((_vm.tiles),function(tile){return _c('tile',{key:tile.id,attrs:{"id":'tile-' + tile.id,"tile":tile,"lang":_vm.lang}})}),1)],1),_vm._l((_vm.pages),function(page){return _c('page',{key:page.id,staticClass:"content-page animated",class:{
					'fadeOut hidden': !_vm.route.content.includes(page.id),
					'fadeIn': _vm.route.content.includes(page.id)
					},attrs:{"id":'page-' + page.id,"lang":_vm.lang,"page":page}})}),(_vm.activeItem.uuid == 'DEADBEEF')?_c('error-page',{attrs:{"page":_vm.config.error['404'],"lang":_vm.lang}}):_vm._e(),(_vm.route.category == 'landing-page')?_c('landing-page',{staticClass:"animated",class:{
					'fadeOut hidden': !_vm.route.category == 'landing-page',
					'fadeIn': _vm.route.category == 'landing-page'
					},attrs:{"data":_vm.landing,"config":_vm.config,"carousel":_vm.carousel,"tiles":_vm.tiles,"lang":_vm.lang}}):_vm._e(),(_vm.route.category == 'team-page')?_c('team-page',{staticClass:"animated",class:{
					'fadeOut hidden': !_vm.route.category == 'team-page',
					'fadeIn': _vm.route.category == 'team-page'
					},attrs:{"pages":_vm.pages,"lang":_vm.lang}}):_vm._e(),(_vm.route.category == 'contact-page')?_c('contact-page',{staticClass:"animated",class:{
					'fadeOut hidden': !_vm.route.category == 'contact-page',
					'fadeIn': _vm.route.category == 'contact-page'
					}}):_vm._e()],2),_c('section-footer',{attrs:{"config":_vm.config,"lang":_vm.lang}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
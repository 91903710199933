<template>
    <div class="page-wrapper">

        <template v-for="page in pages">
            <div class="person" v-if="page.type == 'two-column-page'" :key="page.id">
                <div class="sub-wrapper">
                    <div class="photo" :style="{
                        'background-image': 'url(' + $baseUriBack + page.img + ')'
                        }"></div>

                    <div class="text-wrapper">
                        <div class="name">{{page.title[lang]}}</div>

                        <div class="text" v-html="page.summary.text[lang]">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id scelerisque magna. Phasellus eros leo, tempus ac posuere sagittis, tincidunt ut odio. Praesent eget porta leo. Integer sit amet magna leo. Etiam rhoncus non enim dapibus consectetur. Cras tempor pretium risus, ut gravida ante laoreet in.</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    props: ['pages', 'lang'],

    mounted() {
        
    }
}
</script>

<style scoped>

    .page-wrapper {
        @apply w-full px-4;
    }

    .person {
        @apply grid mb-6;

        grid-template-rows: auto auto auto;
    }

    .name {
        @apply w-full font-team-heading;

      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }

    .photo {
      @apply w-48 h-48 mx-auto mb-2 bg-center bg-no-repeat bg-cover;
      border-radius: 6rem;
      border: 4px solid #ff6600;
    }

    .text-wrapper {
        @apply relative;
    }

    .text >>> p {
        @apply mb-2 font-team-body text-justify;

        font-size: 1rem;
    }

    .text >>> p:last-child {
        @apply mb-0;
    }
    
    .nav {
        @apply w-full text-right font-bold;
    }

    @screen origame-md {
        .page-wrapper {
            @apply w-origame-md mx-auto;
        }

        .person {
            @apply mb-8;

          width: 28.33%;
          margin-left: 2.5%;
          margin-right: 2.5%;
          margin-top: 60px;
          float: left;
        }

        .sub-wrapper {
        }

        .text-wrapper {
            @apply grid;

            grid-template-rows: auto 1fr auto;
        }

        .nav {
            @apply mt-2;
        }
    }

    @screen origame-lg {
        .page-wrapper {
            @apply w-origame-lg;
        }
    }
</style>
<template>
	<div>
		<div class="tile-wrapper" :class="tile.type">
			<div v-if="tile.type == 'image-tile'" class="tile" :style="tileStyle">
				<router-link :to="tile.uri[lang]" class="inner" :style="innerStyle" 
					v-if="tile.uriType == 'int'">
					
					<span class="inner-text" :style="innerTextStyle" v-html="tile.title[lang]"></span>
				</router-link>

				<a :href="tile.uri" class="inner" :style="innerStyle" v-if="tile.uriType == 'ext'">
					<span class="inner-text" :style="innerTextStyle" v-html="tile.title[lang]"></span>
				</a>
			</div>

			<div v-if="tile.type == 'color-tile'" class="tile" :style="tileStyle">
				<span class="inner-text" :style="innerTextStyle" v-html="tile.title[lang]"></span>

				<router-link :to="tile.uri[lang]" class="inner" :style="innerStyle" 
					v-if="tile.uriType == 'int'"></router-link>

				<a :href="tile.uri" class="inner" :style="innerStyle" v-if="tile.uriType == 'ext'"></a>
			</div>

			<div v-if="tile.type == 'heading-tile'" class="tile" :style="tileStyle">
				<div class="inner">
					<span class="inner-text" :style="innerTextStyle" v-html="tile.title[lang]"></span>
				</div>
			</div>

			<div v-if="tile.type == 'text-tile'" class="tile" :class="[tile.height]" :style="tileStyle">
				<div class="inner">
					<span class="inner-text" :style="innerTextStyle" v-html="tile.body[lang]"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['tile', 'lang'],

	data() {
		return {
			tileStyle: {},
			innerStyle: {},
			innerTextStyle: {}
		}
	},

	mounted() {
		if(this.tile.type == 'image-tile') {
			this.tileStyle = {
				'background-image': 'url(\'' + this.tile.image + '\')'
			};

			this.innerStyle = {
				'background-color': this.tile.color
			};

			this.innerTextStyle = {
				'color': this.tile.textColor
			};

		} else if(this.tile.type == 'color-tile') {
			this.tileStyle = {
				'background-color': this.tile.color
			};

			this.innerStyle = {
				'background-image': 'url(\'' + this.tile.image + '\')'
			};

			this.innerTextStyle = {
				'color': this.tile.textColor
			};
		}
	}
}
</script>

<style>
/* Landing Page */
.page-wrapper .tiles .tile-wrapper {
	@apply mx-0 px-0;

	margin-bottom: 24px;
}

.landing-tile-wrapper {
	@apply w-full max-w-full;
}


/* Tile page & generic */
.tile-page-wrapper {
	padding: 0 12px;
}

.tile-page {
	@apply w-full max-w-full mx-auto;

	max-width: 100%;
}

.isotope-item {
	@apply w-full max-w-full;
}

@screen tile-page-1 {
	.tile-page {
		@apply max-w-tile-page-1;
	}
}

@screen tile-page-divide {
	.tile-page-wrapper {
		padding: 0 6px;
	}

	.tile-page {
		@apply max-w-full;
	}

	.isotope-item {
		@apply w-1/2;
	}
}

@screen tile-page-2 {
	.tile-page {
		@apply max-w-tile-page-2;
	}
}

</style>

<style scoped>
.tile-wrapper {
	@apply relative w-full mx-auto;

	padding: 0;
	margin-bottom: 24px;
	max-width: 480px;
}

.tile-wrapper:not(.text-tile) {
	max-height: 252px;
}

.tile {
	@apply relative w-full;
	@apply bg-center bg-no-repeat bg-cover rounded-xl;

	padding-bottom: 52.3560209%;
	box-shadow: 0 0 5px rgba(0,0,0,0.25);
}

@screen tile-page-divide {
	.tile-wrapper {
		padding: 0 6px;
		margin-bottom: 12px;
	}
	
	.h-tile-2 {
		padding-bottom: calc(104.7120418% + 12px) !important;
	}

	.h-tile-3 {
		padding-bottom: calc(157.0680627% + 24px) !important;
	}

	.h-tile-4 {
		padding-bottom: calc(209.4240836% + 36px) !important;
	}
}

@screen tile-page-2 {
	.tile-wrapper {
		padding: 0 12px;
		margin-bottom: 24px;
	}
	
	.h-tile-2 {
		padding-bottom: calc(104.7120418% + 24px) !important;
	}

	.h-tile-3 {
		padding-bottom: calc(157.0680627% + 48px) !important;
	}

	.h-tile-4 {
		padding-bottom: calc(209.4240836% + 72px) !important;
	}
}


/* Generic styling */
.tile > .inner,
.tile > .inner-text {
	@apply absolute w-full h-full max-w-full max-h-full;
	@apply flex flex-col items-center justify-center;
}


/* Heading tile */
.heading-tile .tile {
	box-shadow: none;
}

.heading-tile .inner {
	@apply px-10 text-center;
}

.heading-tile .inner-text {
	@apply font-tile-heading text-tile-heading-base leading-tile-heading-base;
}


/* Image and color tile */
.image-tile .inner,
.color-tile .inner {
	@apply transition-all duration-200 ease-in;
}

.image-tile .inner-text,
.color-tile .inner-text {
	@apply px-4 text-center font-tile-image text-tile-image-base;
}


/* Image tile */
.image-tile .tile {
	@apply bg-no-repeat bg-cover bg-center;
}

.image-tile .inner {
	@apply opacity-0 rounded-xl;
}

.image-tile:hover .inner {
	@apply opacity-100;
}


/* Color tile */
.color-tile .inner {
	@apply opacity-0 bg-no-repeat bg-cover bg-center rounded-xl;
}

.color-tile:hover .inner-text {
	@apply opacity-0;
}

.color-tile:hover .inner {
	@apply opacity-100;
}


/* Text tile */
.text-tile .tile {
	@apply p-0;

	box-shadow: none;
}

.text-tile .inner {
	@apply static h-auto;
}

.text-tile .inner-text {
	@apply max-h-full pt-3 font-tile-text-body;
	@apply text-tile-text-base;
}

.text-tile .inner-text >>> p {
	@apply mb-3;
}

.text-tile .inner-text >>> strong,
.text-tile .inner-text >>> b,
.text-tile .inner-text >>> span {
	@apply font-tile-text-highlights;
}


@screen origame-xxs {
	.text-tile .inner-text {
		@apply text-tile-text-xxs;
	}

	.heading-tile .inner-text {
		@apply text-tile-heading-xxs leading-tile-heading-xxs;
	}

	.image-tile .inner-text,
	.color-tile .inner-text {
		@apply text-tile-image-xxs;
	}
}

@screen tile-page-1 {
	.text-tile .inner-text {
		@apply text-tile-text-tp-1;
	}

	.heading-tile .inner-text {
		@apply text-tile-heading-tp-1 leading-tile-heading-tp-1;
	}

	.image-tile .inner-text,
	.color-tile .inner-text {
		@apply text-tile-image-tp-1;
	}
}

@screen origame-sm {
	.text-tile .inner-text {
		@apply text-tile-text-sm;
	}

	.heading-tile .inner-text {
		@apply text-tile-heading-sm leading-tile-heading-sm;
	}

	.image-tile .inner-text,
	.color-tile .inner-text {
		@apply text-tile-image-sm;
	}
}

@screen tile-page-divide {
	.text-tile .tile {
		/*@apply h-tile-3;*/
	}

	.text-tile .inner {
		@apply absolute h-full;
	}

	.text-tile .inner-text {
		@apply text-tile-text-tp-d;
	}

	.heading-tile .inner-text {
		@apply text-tile-heading-tp-d leading-tile-heading-tp-d;
	}

	.image-tile .inner-text,
	.color-tile .inner-text {
		@apply text-tile-image-tp-d;
	}
}

@screen tile-page-2 {
	.text-tile .inner-text {
		@apply text-tile-text-tp-2;
	}

	.heading-tile .inner-text {
		@apply text-tile-heading-tp-2 leading-tile-heading-tp-2;
	}

	.image-tile .inner-text,
	.color-tile .inner-text {
		@apply text-tile-image-tp-2;
	}
}
</style>

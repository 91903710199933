<template>
<div>
	<team-member-page v-if="page.type == 'two-column-page'" :page="page" :lang="lang"></team-member-page>
	<big-banner-page v-if="page.type == 'big-banner-page'" :page="page" :lang="lang"></big-banner-page>
</div>
</template>

<script>
export default {
	props: ['page', 'lang'],

	data () {
		return {

		}
	},

	methods: {

	},

	computed: {

	},

	mounted() {

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

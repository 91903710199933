<template>
	<div class="page-wrapper">
		<div class="banner-wrapper" :style="{
				'background-color': page.banner.color,
				'color': page.banner.textColor
			}">
			
			<div class="banner">
				<h1>{{page[this.lang].title}}</h1>
			</div>
		</div>

		<div class="content">
			<img :src="page.img">
		</div>
	</div>
</template>

<script>
export default {
	props: ['page', 'lang'],

	data() {
		return {

		}
	},

	mounted() {

	}
}
</script>

<style scoped>
	.page-wrapper {
		@apply w-full mx-auto;
	}

	img {
		@apply block w-full mx-auto;
	}

/*
	h1 {
		@apply mb-4 text-6xl text-origame-prim;
	}*/

	.banner-wrapper {
		@apply flex flex-col justify-center w-full px-4 h-24;
		@apply text-left bg-origame-prim;
	}

	.banner h1 {
		@apply text-3xl font-medium;
	}

	@screen origame-md {
		.banner,
		.content {
			@apply w-origame-md mx-auto;
		}
	}

	@screen origame-lg {
		.banner,
		.content {
			@apply w-origame-lg;
		}
	}
</style>

<template>
    <div class="page-wrapper">
    <form @submit.prevent="submit">
        <input type="text" name="name" placeholder="Name">
        <input type="email" name="email" placeholder="youremail@example.com">
        <textarea name="message" placeholder="Message" />
        <button @click="sendForm()" type="submit" id="submit">Submit</button>
    </form> 
    </div>
</template>

<script>
export default {
    methods: {
        sendForm() {
            console.log('test');

            var data = {
                contact_form: [{
                    target_id: 'feedback'
                }],
                name: [{
                    value: 'name x'
                }],
                mail: [{
                    value: 'mail y'
                }],
                subject: [{
                    value: 'subject z'
                }],
                message: [{
                    value: 'message o'
                }]
            };

            this.$http('/entity/contact_message', data);
        }
    }
}
</script>

<style scoped>
    .page-wrapper {
        @apply w-full px-4;
    }

    form {
        @apply w-full;
    }

    input,
    textarea {
        @apply block p-2 mb-4;

        border: 1px solid black;
    }

    input {
        @apply w-2/4;
    }

    textarea {
        @apply w-full;
    }

    @screen origame-md {
        .page-wrapper {
            @apply w-origame-md mx-auto;
        }
    }

    @screen origame-lg {
        .page-wrapper {
            @apply w-origame-lg mx-auto;
        }
    }
</style>
<template>
	<div class="page-wrapper">

		<div class="block-wrapper block-1-wrapper">
			<div class="block-1">

				<carousel :items="carousel" :lang="lang" :config="config"></carousel>
				
			</div>
		</div>

		<div class="block-wrapper block-2-wrapper">
			<div class="block-2">

				<div class="left-col">
					<div class="text" v-html="data[lang].text['2']"></div>
				</div>

				<div class="right-col">
					<div class="events-box">
						<div class="image-1">
							<img :src="$baseUriBack + data.img['1']">
						</div>

						<a :href="data.link['1']" class="image-2">
							<div>
								<img :src="$baseUriBack + data.img['2']">
							</div>
						</a>

						<a :href="data.link['2']" class="image-3">
							<div>
								<img :src="$baseUriBack + data.img['3']">
							</div>
						</a>

						<div class="upcoming" v-html="data[lang].html['1']"></div>
					</div>
				</div>

			</div>
		</div>
		
		<div class="block-wrapper block-3-wrapper">
			<div class="block-3">

				<div class="left-col"></div>

				<div class="right-col">
					<div class="heading" v-html="data[lang].heading[1]"></div>
				</div>

			</div>
		</div>

		<div class="block-4-5-6-7-wrapper" :style="{
				...data.style['1'].parsed,
				'background-image': 'url(' + this.$baseUriBack + this.data.img['4'] + ')'
			}">

			<div class="block-wrapper block-4-wrapper">
				<div class="block-4">

					<div class="left-col">
						<div class="tiles">
							<tile v-for="idx in data.tiles['1']" :id="'tile-' + tiles[idx].id" 
								:key="tiles[idx].id" :tile="tiles[idx]" :lang="lang" class="landing-tile-wrapper"></tile>
						</div>
					</div>

					<div class="right-col">
						<div class="text" v-html="data[lang].text['4']"></div>
					</div>
				</div>
			</div>

			<div class="block-wrapper block-5-wrapper">
				<div class="block-5">

					<div class="left-col">
						<div class="text" v-html="data[lang].text['3']"></div>
					</div>

					<div class="right-col">
						<div class="tiles">
							<tile v-for="idx in data.tiles['2']" :id="'tile-' + tiles[idx].id" :key="tiles[idx].id" 
								:tile="tiles[idx]" :lang="lang" class="landing-tile-wrapper"></tile>
						</div>
					</div>
				</div>
			</div>

			<div class="block-wrapper block-6-wrapper">
				<div class="block-6">

					<div class="tiles">
						<tile v-for="idx in data.tiles['3']" :id="'tile-' + tiles[idx].id" :key="tiles[idx].id" 
							:tile="tiles[idx]" :lang="lang" class="landing-tile-wrapper"></tile>
					</div>

				</div>
			</div>

			<div class="block-wrapper block-7-wrapper">
				<div class="block-7">

					<div class="heading" v-html="data[lang].heading[2]"></div>

				</div>
			</div>

		</div>

	</div>
</template>

<script>
export default {
	props: ['data', 'carousel', 'config', 'tiles', 'lang'],

	data() {
		return {

		}
	},

	mounted() {
		var c = this.data.color['1'];
		
		// Set lint color on first paragraph in text blocks
		this.data.nl.text['2'] = this.data.nl.text['2'].replace('<p', '<p style="color: '+ c +'"');
		this.data.en.text['2'] = this.data.en.text['2'].replace('<p', '<p style="color: '+ c +'"');

		this.data.nl.text['3'] = this.data.nl.text['3'].replace('<p', '<p style="color: '+ c +'"');
		this.data.en.text['3'] = this.data.en.text['3'].replace('<p', '<p style="color: '+ c +'"');

		this.data.nl.text['4'] = this.data.nl.text['4'].replace('<p', '<p style="color: '+ c +'"');
		this.data.en.text['4'] = this.data.en.text['4'].replace('<p', '<p style="color: '+ c +'"');
	}
}
</script>

<style scoped>


	.page-wrapper,
	.block-wrapper {
		@apply w-full;
	}

	.tiles .landing-tile-wrapper {
		@apply flex flex-col items-center;
	}

	.block-4-wrapper,
	.block-5-wrapper,
	.block-6-wrapper,
	.block-7-wrapper {
		background-color: rgba(255, 255, 255, .65);
	}


	.text >>> p {
		@apply mb-3;
	}

	.text >>> p:not(:first-child) {
		@apply font-landing-body text-landing-body-sm;
	}

	.text >>> p:first-child {
		@apply font-landing-body-heading text-landing-body-heading-sm;
	}

	.text >>> p > strong,
	.text >>> p > b {
		@apply font-bold;
	}


	.block-1-wrapper {
		@apply relative overflow-hidden;
	}

	.block-1 {
		width: 100%;
		height: 100%;
	}

	.carousel {
		@apply relative w-full;
	}

	.block-1-wrapper,
	.carousel {
		height: 90vh;
	}



	.block-2-wrapper {
		background: rgb(0, 162, 223);
	}

	.block-2 {
		@apply grid grid-cols-2 p-8;
	}

	.block-2 .left-col {
		@apply col-span-2 relative;
	}

	.block-2 .right-col {
		@apply col-span-2;
	}
	
	.block-2 .text >>> p {
		@apply text-white;
    color: #ffffff !important;
	}

	.block-2 .events-box {
		@apply w-full grid grid-cols-2 gap-4;
	}

	.block-2 .events-box .image-1 {
		@apply col-span-2;
    border-radius: .75rem;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
    overflow: hidden;
	}

	.block-2 .events-box .image-1 img {
		@apply block max-w-full mx-auto;
	}

	.block-2 .events-box .image-2,
	.block-2 .events-box .image-3 {
		@apply flex flex-col justify-center bg-white;
    border-radius: .75rem;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
    overflow: hidden;
	}

	.block-2 .events-box .upcoming {
		@apply col-span-2 h-80 flex flex-row items-center justify-center;
		@apply text-5xl text-white text-center;

		/*background: #ced64c;*/
    height: 19rem;
    overflow: hidden;
    border-radius: .75rem;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
	}

	.block-2 .events-box .upcoming > span {
		@apply m-1 w-full h-full inline-block;
	}



	.block-3-wrapper {
		background: rgb(0, 162, 223);
	}

	.block-3 {
		@apply grid grid-cols-2 pb-8 px-8;
	}

	.block-3 .left-col {
		@apply col-span-2 hidden;
	}

	.block-3 .right-col {
		@apply col-span-4 w-full;
	}

	.block-3 .heading {
		@apply text-right;
	}

	.block-3 .heading >>> p {
		@apply text-white font-landing-block-3-heading text-landing-block-3-heading-sm;

		font-weight: 400;
		text-transform: capitalize;
	}

	.block-3 .text {
		@apply text-2xl text-white;
	}



	.block-4 {
		@apply grid grid-cols-2 pt-8 px-8;
	}

	.block-4 .left-col {
		@apply col-span-2;
	}

	.block-4 .right-col {
		@apply col-span-2;
	}

	.block-4 .text {
		@apply text-center;
    background: #ffffff;
    padding: 10px;
	}

	.block-4 .text >>> p:last-child {
		@apply mb-8;
	}



	.block-5 {
		@apply grid grid-cols-2 px-8;
	}

	.block-5 .left-col {
		@apply col-span-2;
		order: 1;
	}

	.block-5 .right-col {
		@apply col-span-2;
		order: 0;
	}

  .block-5 .text {
    background: #ffffff;
    padding: 10px;
  }

	.block-5 .text >>> p:last-child {
		@apply mb-8;
	}
	


	.block-7 {
		@apply pb-8;
	}

	.block-7 .heading {
		@apply w-full text-center;
    color: #ff6600;
	}
	
	.block-7 .heading >>> p {
		@apply text-landing-footer-sm;
	}
	
	/*
	.block-4 .right-col {
		@apply w-6/12 pl-5;

		background-image: url('../../src/assets/img/bg.png');
		background-repeat: no-repeat;
		background-position: bottom;
	}
	*/
	


	@screen origame-md {
		.text >>> p:not(:first-child) {
			@apply text-landing-body-md;
		}

		.text >>> p:first-child {
			@apply text-landing-body-heading-md;
		}


		.block-1-wrapper,
		.carousel {
			height: 70vh;
		}

		.block-2 {
			@apply w-origame-md mx-auto px-8 gap-x-8;
		}

		.block-2 .left-col {
			@apply col-span-1;

			/*margin-top: -.5rem;*/
		}
		
		.block-2 .right-col {
			@apply col-span-1;
		}

		.block-2 .events-box {
			@apply w-full grid grid-cols-2 gap-y-4 gap-x-4;
		}


		.block-3 {
			@apply w-origame-md mx-auto px-8;
		}

		.block-3 .right-col {
			@apply col-span-2;
		}

		.block-3 .heading >>> p {
			@apply text-landing-block-3-heading-md;
		}
		

		.block-4 {
			@apply w-origame-md mx-auto px-0 gap-x-8;
		}

		.block-4 .left-col {
			@apply col-span-1;

			/* Size of tiles + part of tile + spacing between */
			margin-top: calc(((200px / 1.5) + (24px * 1)) * -1);
		}

		.block-4 .right-col {
			@apply col-span-1;

      margin-top: -24px;
		}

		.block-4 .tiles .landing-tile-wrapper {
			@apply items-end;
		}

		.block-4 .text {
			@apply text-left;
		}

		
		


		.block-5 {
			@apply w-origame-md mx-auto px-0 gap-x-8;
		}

		.block-5 .left-col {
			@apply flex flex-col justify-center col-span-1;
			order: 0;
		}

		.block-5 .left-col .text >>> p:first-child {
			@apply inline-block w-full text-right;
		}

		.block-5 .right-col {
			@apply col-span-1;
			order: 1;

			/* Size of tiles + spacing between */
			margin-top: calc(((200px * 1) + (2rem * 1)) * -1);
		}

		.block-5 .tiles .landing-tile-wrapper {
			@apply items-start;
		}



		.block-6 {
			@apply w-origame-md mx-auto px-0 gap-x-8;
		}

		.block-6 .landing-tile-wrapper {
			@apply flex flex-col w-1/2;
		}

		.block-6 .tiles {
			@apply flex flex-row flex-wrap;
		}

		.block-6 .landing-tile-wrapper:nth-child(odd) {
			@apply items-end pr-4;
		}

		.block-6 .landing-tile-wrapper:nth-child(even) {
			@apply items-start pl-4;
		}


		.block-7 {
			@apply w-origame-md mx-auto px-0 gap-x-8;
		}
		
		.block-7 .heading >>> p {
			@apply text-landing-footer-md;
		}
	}



	@screen origame-lg {
		.text >>> p:not(:first-child) {
			@apply text-landing-body-lg;
		}

		.text >>> p:first-child {
			@apply text-landing-body-heading-lg;
		}

		.block-2,
		.block-3,
		.block-4,
		.block-5,
		.block-6,
		.block-7 {
			@apply w-origame-lg;
		}
		
		.block-3 .heading >>> p {
			@apply text-landing-block-3-heading-lg;
		}

		.block-4 .left-col {
			/* Size of tiles + part of tile + spacing between */
			margin-top: calc(((251px * 1) + (251px / 3) + (24px * 2)) * -1);
		}

		.block-5 .right-col {
			/* Size of tiles + spacing between */
			margin-top: calc(((251px * 1) + (24px * 1)) * -1);
		}


		.block-7 .heading >>> p {
			@apply text-landing-footer-lg;
		}
	}


	@screen origame-xl {
		.text >>> p:not(:first-child) {
			@apply text-landing-body-xl;
		}
	}


</style>
